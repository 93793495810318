<script
  setup
  lang="ts"
  generic="RentValue extends DashboardQuery, AreaValue extends DashboardQuery"
>
import type { DashboardQuery } from '@dashboard'

defineProps<{
  rentValue: RentValue
  areaValue: AreaValue
}>()
const modelValue = defineModel<RentValue | AreaValue>()
</script>
<template>
  <div class="flex text-xs text-gray-400" @click.stop="() => {}">
    <span
      :class="[
        'mr-1 transition-colors duration-300',
        modelValue === rentValue && 'text-primary',
      ]"
    >
      Rent
    </span>
    <FormKit
      v-model="modelValue"
      type="toggle"
      :off-value="rentValue"
      :on-value="areaValue"
      track-class="!min-w-8"
      thumb-class="!w-2.5"
      wrapper-class="!mb-0"
      thumb-color-off="#D8FC59"
      thumb-color-on="#D8FC59"
      track-color-off="#4D4D4D"
      track-color-on="#4D4D4D"
    />
    <span
      :class="[
        '-ml-1 transition-colors duration-300',
        modelValue === areaValue && 'text-primary',
      ]"
    >
      Area
    </span>
  </div>
</template>
